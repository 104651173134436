<template>
    <el-dialog title="完工资料上传" :close-on-click-modal="false" :visible.sync="visible" width="961px">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" :inline="true" label-width="80px">
            <el-row>
                <el-col :span="24" id="demo">
                    <el-form-item label="" style="width:100%">
                        <el-upload ref="uploadImg" class="upload-demo" action="action" :http-request="uploadImg" multiple
                            drag :show-file-list=false :auto-upload="true">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" style="margin-top: -20px;">
                    <el-table :header-cell-style="{ textAlign: 'center' }" v-if="dataForm.records.length > 0"
                        :data="dataForm.records" style="width: 100%;" :cell-style="{ padding: '0px' }" border>
                        <el-table-column type="index" label="No." width="60" align="center">
                        </el-table-column>
                        <el-table-column prop="fileName" label="文件名称" width="230" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-input class="sp-input" v-model="scope.row.fileName" clearable></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="日期" width="160px">
                            <template slot-scope="scope">
                                <el-date-picker class="sp-date" v-model="scope.row.createTime" type="date"
                                    value-format="yyyy-MM-dd" placeholder="选择日期">
                                </el-date-picker>
                            </template>
                        </el-table-column>
                        <el-table-column label="上传人" width="150px">
                            <template slot-scope="scope">
                                <el-input class="sp-input" v-model="scope.row.createMan" clearable></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="备注" width="200px">
                            <template slot-scope="scope">
                                <el-input class="sp-input" v-model="scope.row.remark" placeholder="请填写"
                                    clearable></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label=" " width="100">
                            <template slot-scope="scope">
                                <el-button type="text" @click="delImg(scope.$index)">删除</el-button>
                                <el-button type="text"
                                    @click="viewFile(scope.row.fileUri, scope.row.fileName)">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <el-row class="formBtnGroup" style="margin-top: 60px;">
                <el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()" :disabled="clickFlg">保存</el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
    data() {
        return {
            clickFlg: false,
            title: '',
            addImg: addImg,
            visible: true,
            action: "",
            dataForm: {
                workNo: $common.getItem("workNo"),
                records: []
            },
            dataRule: {
            },
            specialReportId: '',
        };
    },
    components: {

    },
    activated() {

    },
    mounted() {
    },
    methods: {
        init() {
            this.clickFlg = false;
            this.dataForm = {
                workNo: $common.getItem("workNo"),
                records: []
            };
            this.$nextTick(() => {
                this.visible = true;
            })
        },
        //图片
        delImg(index) {
            this.dataForm.records.splice(index, 1);
        },
        uploadImg(param) {
            const formData = new FormData();
            formData.append('file', param.file);
            this.$refs.uploadImg.clearFiles();
            this.$http.post(
                this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/94/0",
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                },
            ).then(res => {
                res = res.data
                if (res.resultCode == 200) {
                    this.dataForm.records.push({
                        filePath: res.body.fileSavePath,
                        createTime: new Date(),
                        createMan: $common.getItem("userName"),
                        fileUri: res.body.fileUri,
                        fileName: this.extractFileName(res.body.fileName),
                        remark: ""
                    });
                } else {
                    this.$message.info('文件上传失败')
                }
            })
        },
        extractFileName(fileName) {
            let index = fileName.lastIndexOf(".");
            return fileName.substr(0, index)
        },
        viewFile(url, filename) {
            filename = this.getSuffix(url, filename)
            this.getBlob(url, (blob) => {
                this.saveAs(blob, filename)
            })
        },
        getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
        getBlob(url, cb) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function () {
                if (xhr.status === 200) {
                    cb(xhr.response)
                }
            }
            xhr.send()
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            }
            else {
                var link = document.createElement('a')
                var body = document.querySelector('body')

                link.href = window.URL.createObjectURL(blob)
                link.download = filename

                link.style.display = 'none'
                body.appendChild(link)

                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        },
        dataFormSubmit() {
            this.clickFlg = true;
            if (this.dataForm.records == undefined || this.dataForm.records.length <= 0) {
                this.$message.error('请上传完工资料文件');
                this.clickFlg = false;
                return;
            }
            for(var i = 0; i < this.dataForm.records.length; i++) {
                if(this.dataForm.records[i].fileName == '' || this.dataForm.records[i].fileName == undefined) {
                    this.$message.error('请填写完工资料文件名称');
                    this.clickFlg = false;
                    return;
                }
            }
            this.$http({
                url: this.$store.state.httpUrl + "/business/filekindconfig/getFileKindConfigRecId",
				method: 'post',
				data: this.$http.adornData({
                    workNo: $common.getItem("workNo"),
					kindName: '完工资料',
                })
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.finishFileId = data.body
                    this.$refs["dataForm"].validate(valid => {
                        if (valid) {
                            let _url = "/business/projectworkfileinfo/batchSave";
                            this.$http({
                                url: this.$store.state.httpUrl + _url,
                                method: "post",
                                data: this.$http.adornData({
                                    workNo: $common.getItem("workNo"),
                                    subjectNo: $common.getItem("subjectNo"),
                                    fileKind: this.finishFileId,
                                    records: this.dataForm.records
                                })
                            }).then(({ data }) => {
                                if (data && data.resultCode === 200) {
                                    this.$message({
                                        message: "操作成功",
                                        type: "success",
                                        duration: 1500,
                                        onClose: () => {
                                            this.visible = false;
                                            this.$parent.getDataList();
                                        }
                                    });
                                } else {
                                    this.clickFlg = false;
                                }
                            });
                        }
                    });
                } else {
                    this.clickFlg = false;
                }
            })
        }
    }
};
</script>

<style scoped="scoped">
::v-deep(.el-table th.el-table__cell) {
    background-color: #d3e2f4 !important;
    font-size: 15px;
    padding: 2px 0;
}

.sp-input /deep/ .el-input__inner {
    width: 100% !important;
}

.sp-date /deep/ .el-input__inner {
    width: 140px !important;
}

.upload-demo /deep/ .el-upload-dragger {
    width: 260px;
    height: 100px;
    margin-left: 25px;
}

.upload-demo /deep/ .el-upload-dragger .el-icon-upload {
    font-size: 30px;
    margin: 20px 1px;
    line-height: 20px;
}

.upload-demo /deep/ .el-upload-dragger .el-upload__text {
    line-height: 5px;
}
</style>
